$primary-color: #5928e9;


@mixin respond-to($breakpoint) {
    @if $breakpoint ==mobile {
        @media (max-width: 599px) {
            @content;
        }
    }

    @else if $breakpoint ==tablet {
        @media (min-width: 600px) and (max-width: 959px) {
            @content;
        }
    }

    @else if $breakpoint ==desktop {
        @media (min-width: 960px) {
            @content;
        }
    }
}

.top-home-footer-section {
    margin-top: 40px;
    padding-top: 80px;
    padding-bottom: 80px;
    border-top: 1px solid #141436;

    .social-menu-new-footer {
        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 14px;
            margin: 0px;
            padding: 0px;

            li {
                list-style: none;

                .fab {
                    font-size: 18px;
                    line-height: 46px;
                    transition: .3s;
                    color: #000;
                }

                a {
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 46px;
                    height: 46px;
                    border-radius: 50%;
                    background-color: #fff;
                    text-align: center;
                    transition: .6s;
                    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
                }

                :hover {
                    transform: translate(0, -10%);
                }
            }
        }
    }

    .top-home-footer-section-1 {
        border-radius: 30px;

        a {
            color: #FFF;
            text-decoration: none;
            font-size: 16px;
            margin-bottom: 10px;
            text-align: center;

            @include respond-to(mobile) {
                text-align: start;
            }

            @include respond-to(tablet) {
                text-align: start;
            }
        }

        svg {
            color: $primary-color;
        }

        .footer-brand {
            filter: brightness(0) invert(1);
        }
    }
}