@import './footer.scss';
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

$primary-color: #5928e9;
$font-stack: "Poppins", sans-serif;
$color-1: #ffffff;
$background-color-1: #010215;

@mixin respond-to($breakpoint) {
    @if $breakpoint ==mobile {
        @media (max-width: 599px) {
            @content;
        }
    }

    @else if $breakpoint ==tablet {
        @media (min-width: 600px) and (max-width: 959px) {
            @content;
        }
    }

    @else if $breakpoint ==desktop {
        @media (min-width: 960px) {
            @content;
        }
    }
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 10px;
}

body {
    font-family: $font-stack !important;
    background-color: $background-color-1 !important;
    color: $color-1 !important;

    a {
        color: #FFF;
    }
}

.top-new-navbar-css {
    .navbar {
        box-shadow: none;
        background-color: #5928e914;
        backdrop-filter: blur(15px);
        border-bottom: 1px solid #141436;

        @include respond-to(tablet) {
            padding: 6px 0px;
        }

        @include respond-to(desktop) {
            padding: 6px 0px;
        }

    }

    .nav-items {
        color: $color-1;
        text-transform: capitalize;
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-family: $font-stack !important;

        svg {
            width: 18px;
            height: 18px;
            margin-top: 4px;

            @include respond-to(mobile) {
                margin-top: 0px;
            }
        }
    }



}

.navbar-brand {
    font-family: $font-stack !important;
    font-size: 18px;
    text-decoration: none;

    img {
        filter: brightness(0) invert(1);
        margin-top: 10px;
        width: 150px;
        height: auto;

        @include respond-to(mobile) {
            width: 80px;
        }
    }

    @include respond-to(mobile) {
        font-size: 14px;
    }
}

.glow-effect-1 {
    top: 30vh;
    left: 0px;
    width: 20vw;
    height: 40vh;
    position: absolute;
    background-color: $primary-color;
    z-index: -9;
    border-radius: 50%;
    filter: blur(260px);
}

.glow-effect-2 {
    top: 30vh;
    right: 0px;
    width: 20vw;
    height: 40vh;
    position: absolute;
    background-color: $primary-color;
    z-index: -9;
    border-radius: 50%;
    filter: blur(260px);
}

.top-home-banner-section {
    padding-bottom: 140px;

    .b-top-text-1 {
        line-height: 6rem;
        font-size: 3rem;
        margin-bottom: 1.4rem;
        text-align: center;

        @include respond-to(mobile) {
            line-height: 2rem;
            font-size: 1.6rem;
        }

        @include respond-to(tablet) {
            line-height: 3rem;
            font-size: 2rem;
        }
    }

    .b-top-text-2 {
        font-size: 1.1rem;
        text-align: center;
        opacity: 0.9;

        @include respond-to(mobile) {
            font-size: 1rem;
        }

        @include respond-to(tablet) {
            font-size: 1.2rem;
        }
    }

    .b-top-text-btn-1 {
        background: $primary-color;
        color: #FFF;
        font-weight: 800;
        padding: 10px 50px;
        border-radius: 50px;
    }
}

.top-home-banner-section-2 {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #5928e914;
    backdrop-filter: blur(15px);
    position: relative;
    z-index: 99;

    .top-home-banner-section-2-2 {
        text-align: center;

        .top-home-sec-card-1 {
            color: #FFF;
            background-color: transparent;
            height: 100%;
        }

        h5 {
            font-size: 1.4rem;
            font-weight: 800;
            margin: 10px;
        }

        p {
            font-size: 14px;
            margin: 0px;
            opacity: 0.7;
        }

        .b-top-icon-2 {
            font-size: 3rem;
        }
    }
}

.top-home-banner-section-3 {
    padding-top: 60px;
    padding-bottom: 60px;

    h5 {
        font-size: 2.4rem;
        margin: 0px;

        @include respond-to(mobile) {
            font-size: 1.6rem;
        }
    }

    p {
        font-size: 18px;
        opacity: 0.9;
    }
}

.top-home-banner-section-4 {
    padding-top: 60px;
    padding-bottom: 60px;

    h5 {
        margin-top: 10px;
        font-size: 18px;
        margin-bottom: 0px;
    }

    p {
        font-size: 14px;
    }

    .section-4-card-1 {
        background-color: #0A062A;
        padding: 20px;
        border-radius: 20px;
    }

    .section-4-card-icon-1 {
        border-radius: 25px !important;
        background-color: rgba(88, 40, 233, 0.1176470588) !important;
        border: 1px solid #5928e9 !important;
        font-size: 44px;
        padding: 10px;
    }
}

.error-page-1 {
    padding-top: 20vh;

    .b-top-text-err-1 {
        line-height: 6rem;
        font-size: 6rem;
        text-align: center;
        background: linear-gradient(91.26deg, $primary-color 0%, #EF96FF 21.74%, $primary-color 54.03%, #FFAA6C 85.28%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include respond-to(mobile) {
            line-height: 3rem;
            font-size: 3rem;
        }

        @include respond-to(tablet) {
            line-height: 3rem;
            font-size: 3rem;
        }
    }

    .b-top-text-err-btn-1 {
        padding: 12px 36px !important;
        border-radius: 25px !important;
        background-color: rgba(88, 40, 233, 0.1176470588) !important;
        border: 1px solid #5928e9 !important;
        color: #FFF;
        font-weight: 800;
        padding: 10px 50px;
        border-radius: 50px;
        text-decoration: none;
        font-size: 16px;
    }

}

.error-page-1+.top-home-footer-section {
    display: none;
}

.nav-items-top-1 {
    position: relative;
    margin-right: 10px !important;
    line-height: 24px !important;
    padding: 6px 16px !important;
    border-radius: 25px !important;
    background-color: #5828e91e !important;
    border: 1px solid $primary-color !important;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    text-align: center !important;
    color: #FFF !important;
    font-size: 14px !important;


    @include respond-to(mobile) {
        font-size: 12px !important;
        padding: 2px 6px !important;
        margin-right: 4px !important;
    }

    .nav-items-top-1-1 {
        margin-top: -2px !important;
        margin-right: 6px !important;
    }
}
